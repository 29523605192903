/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';

const openplay = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[1].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
             <section className="page-section notbottompaddsec nopaddbottsec">
                <div className="container">
                <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div>
                   
                </div>
            </section> 

          

            

            <section
                className="whywelist page-section bg-primary text-white"
                id="openpbasic"
            >
             <div className="container smallestwdt">
                    <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    />
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    />

                    {post.acf.open_play_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_price,
                                }}
                            />
                        </h5>
                    ))}
                    {/* <p>
                    Call for additional pricing for families with more than 3 children.
                    </p> */}
                </div>
                {/* <div className="container flexwrap whywebluelist">
                    <div className="whylistlft flexbox whylisttxt todwn">
                        <img
                            className="whiteborderimg"
                            src={
                                post.acf.open_play_basic_info_image.localFile
                                    .childImageSharp.fluid.src
                            }
                            loading="lazy"
                            alt="open img"
                        />
                    </div>

                    <div className="whylistrgt flexbox toup">
                        <h2
                            className="yellowtxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_title,
                            }}
                        />
                        <ul
                            className="startxt normalfontlist"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_basic_info_content,
                            }}
                        />
                    </div>
                </div> */}
            </section>

            {/* <section className="page-section bg-secondary text-white centersec">
                <div className="container smallestwdt">
                    <h2
                        className="yellowtxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_title,
                        }}
                    />
                    <p
                        className="biggertxt"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_membership_description,
                        }}
                    />

                    {post.acf.open_play_membership_list.map((membership, i) => (
                        <h5 key={`kt_${i}`}>
                            <span
                                className="yellowtxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_name,
                                }}
                            />
                            {' : '}
                            <span
                                dangerouslySetInnerHTML={{
                                    __html:
                                        membership.open_play_membership_price,
                                }}
                            />
                        </h5>
                    ))}
                    
                </div>
            </section> */}
            <section className="page-section openphours display_none">
                <div className="container smallestwdt">
                    <div className="twothirdcol">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_schedule_title,
                            }}
                        />
                        
                        <ul className="startxt starlistspacing">
                       
                       {/* <li className="greentxt">  PLEASE CALL OR VISIT OUR SOCIAL MEDIA PAGES FOR CURRENT HOURS!</li> */}
                    {post.acf.open_play_schedule_work_days.map(
                                (schedule, i) => (
                                    <li className="greentxt" key={i}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_day,
                                            }}
                                        />
                                        : 
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}
                            {post.acf.open_play_schedule_weekend.map(
                                (schedule, i) => (
                                    <li className="bluetxt" key={`ii_${i}`}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_day,
                                            }}
                                        />
                                        :
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    schedule.open_play_schedule_weekend_time,
                                            }}
                                        />
                                    </li>
                                )
                            )}  
                        </ul>
                        <p>
                        HOURS VARY BASED ON PRIVATE RENTALS AND BIRTHDAY PARTY RESERVATIONS. <br />PLEASE CHECK OUR SCHEDULE OR CALL FOR MORE INFORMATION.
                        </p>
                    </div>

                    <div className="onethirdcol">
                        <a
                            href="/register"
                            style={{ background: 'none', padding: 0 }}
                        >
                            <h6
                                className="greentxt"
                                dangerouslySetInnerHTML={{
                                    __html:
                                        post.acf.open_play_registration_title,
                                }}
                            />
                        </a>
                {post.acf.open_play_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code,
                                    }}
                                />
                            )
                        )}   
                        
                    </div>
                </div>
            </section>
            <div className="container text_center mt-15 display_none">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>
            <section className="page-section">
                <div className="container smallestwdt">
                    <div className="greenlft">
                        <img src={Greenlogo} alt="greenlogo" loading="lazy" />
                    </div>

                    <div className="greenrgt">
                        <h2
                            className="bluetxt"
                            dangerouslySetInnerHTML={{
                                __html: post.acf.open_play_green_products_title,
                            }}
                        />
                        <p
                            className="biggertxt lastitem"
                            dangerouslySetInnerHTML={{
                                __html:
                                    post.acf.open_play_green_products_content,
                            }}
                        />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default openplay;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_basic_info_content
                open_play_basic_info_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_basic_info_title
                open_play_content_right
                open_play_content_second
                open_play_green_products_content
                open_play_green_products_title
                open_play_image_left {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
                open_play_membership_description
                open_play_membership_list {
                    open_play_membership_name
                    open_play_membership_price
                }
                open_play_membership_title
                open_play_please_call_ahead_text
                open_play_registration_title
                open_play_register_widget_codes {
                    open_play_register_widget_code
                }
                open_play_schedule_title
                open_play_schedule_work_days {
                    open_play_schedule_day
                    open_play_schedule_time
                }
                open_play_schedule_weekend {
                    open_play_schedule_weekend_day
                    open_play_schedule_weekend_time
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
